import { useState } from "react"
import { useForm } from "react-hook-form"
import { Container, Row, Col } from "react-bootstrap"
import { createJobScraping, getJobScraping } from "api/jobs/scraping"

import Header from "components/Header"
import Input from "components/forms/Input"

import "./New.scss"

const NewJobRoute = () => {
  const [errors, setErrors] = useState({})

  const { handleSubmit, register } = useForm()

  const onSubmit = data => {
    return createJobScraping(data)
      .then(response => {
        pollScrapingStatus(response?.data?.id)
      })
      .catch(response => {
        setErrors(response?.data)
      })
  }

  const pollScrapingStatus = scrapingId => {
    if (!scrapingId) return
    console.log("Polling scraping status for", scrapingId)

    const interval = setInterval(() => {
      getJobScraping(scrapingId)
        .then(response => {
          const status = response?.data?.status
          if (status === "completed") {
            clearInterval(interval)
            console.log("Scraping completed")
            console.log(response?.data)
          }
        })
        .catch(error => {
          clearInterval(interval)
          console.error("Error polling job status:", error)
        })
    }, 3000)

    // Cleanup interval on component unmount
    return () => clearInterval(interval)
  }

  const getErrors = name => {
    return (errors && errors[name]) || []
  }

  return (
    <>
      <Header title="New Job" icon="job" className="create-job" />
      <div className="new-job">
        <Container fluid>
          <Row>
            <Col>
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="input-group mb-3">
                  <Input
                    className="form-control-lg"
                    type="text"
                    name="external_url"
                    label="External Job URL"
                    error={getErrors("external_url")[0]}
                    register={register}
                  />
                  <button type="submit" className="btn btn-primary">
                    Submit
                  </button>
                </div>
              </form>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  )
}

export default NewJobRoute
