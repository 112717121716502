import { useState, useEffect } from "react"
import { useParams } from "react-router-dom"
import { getCompany } from "api/companies"
import { createJob } from "api/jobs"
import { useNavigate } from "react-router-dom"
import { Container, Row, Col } from "react-bootstrap"

import Header from "components/Header"
import StatusBar from "components/companies/jobs/StatusBar"
import StepOne from "components/companies/jobs/StepOne"
import StepTwo from "components/companies/jobs/StepTwo"
import StepThree from "components/companies/jobs/StepThree"

import "./New.scss"

const NewJobRoute = () => {
  const { id } = useParams()

  const [company, setCompany] = useState({})
  const [step, setStep] = useState(1)
  const [errors, setErrors] = useState([])
  const [form, setForm] = useState({
    1: {
      title: "",
      featured: false,
      location_type: null,
      role: null,
      ote_min: undefined,
      ote_max: undefined,
      open_to_new: false,
      metro_location_ids: [],
      sales_division: null,
      years_of_experience: null,
      external_url: null,
    },
    2: {
      description: null,
    },
  })

  useEffect(() => {
    getCompany(id)
      .then(response => {
        setCompany(response.data)
      })
      .catch(error => {
        console.error(error)
      })
  }, [id])

  const navigate = useNavigate()

  const getErrors = name => {
    return (errors && errors[name]) || []
  }

  const setDescription = value => {
    setForm({
      ...form,
      2: {
        description: value,
      },
    })
  }

  const handleCreateJob = () => {
    const jobObj = {
      title: form[1].title,
      role_id: form[1].role,
      metro_location_ids: form[1].metro_locations
        ? form[1].metro_locations.map(location => location.id)
        : [],
      featured: form[1].featured,
      open_to_new: form[1].open_to_new,
      location_type: form[1].location_type,
      ote_min: form[1].ote_min.length ? form[1].ote_min : null,
      ote_max: form[1].ote_max.length ? form[1].ote_max : null,
      description: form[2].description,
      sales_division: form[1].sales_division,
      years_of_experience: form[1].years_of_experience,
      external_url: form[1].external_url,
    }

    if (form[1].external_url === "") {
      delete jobObj.external_url
    }

    createJob(jobObj, company.id)
      .then(response => {
        navigate(`/jobs/${response.data.id}`)
      })
      .catch(error => setErrors(error))
  }

  return (
    <>
      <Header title="New Job" icon="job" className="create-job" />
      <div className="new-job">
        <StatusBar step={step} />
        <div className="job-form">
          <Container className="pt-4 pb-4">
            <Row>
              <Col sm={{ offset: 3, span: 6 }}>
                {step === 1 && (
                  <StepOne
                    getErrors={getErrors}
                    companyName={company.name}
                    setStep={setStep}
                    setForm={setForm}
                    form={form}
                  />
                )}
                {step === 2 && (
                  <StepTwo
                    setStep={setStep}
                    setDescription={setDescription}
                    description={form[2].description}
                  />
                )}
                {step === 3 && (
                  <StepThree
                    setStep={setStep}
                    form={form}
                    handleCreateJob={handleCreateJob}
                  />
                )}
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </>
  )
}

export default NewJobRoute
